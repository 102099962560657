import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {store} from './store/store'; 
import {Provider} from 'react-redux';
import Container from './componentes/container';

import ProductosDePerros
  from './componentes/vistas/productos-de-perros/raciones/raciones';
import VistaDetalleRascadores from './componentes/vistas/productos-de-gatos/rascadores/vista-detalle/vista';

import Snacks from './componentes/vistas/productos-de-perros/snacks/snacks';
import VistaDetalleDelProductoDePerro
from './componentes/vistas/productos-de-perros/raciones/vista-detalle/vista';
import Carrito from './componentes/carrito/carrito';
import Articulos from './componentes/blog/articulos-blog/articulos';
import Articulo from './componentes/blog/articulos-blog/articulo';
import Alimentos
  from './componentes/vistas/productos-de-roedores/alimentos/alimentos';
import VistaDetalleAlimentoRoedores
  from './componentes/vistas/productos-de-roedores/alimentos/vista-detalle/vista';
import Jaulas from './componentes/vistas/productos-de-roedores/jaulas/jaulas';
import VistaDetalleJaulasRoedores
  from './componentes/vistas/productos-de-roedores/jaulas/vista-detalle/vista';
import AlimentosAves
  from './componentes/vistas/productos-de-aves/alimentos/alimentos';
import {ProSidebarProvider} from 'react-pro-sidebar';
import VistaDetalleAlimentoAves
  from './componentes/vistas/productos-de-aves/alimentos/vista-detalle/vista';
import AccesoriosAves
  from './componentes/vistas/productos-de-aves/accesorios/accesorios';
import VistaDetalleAccesorioAves
  from './componentes/vistas/productos-de-aves/accesorios/vista-detalle/detalle';
import AccesoriosPeces
  from './componentes/vistas/productos-de-peces/accesorios/accesorios';
import VistaDetalleAccesorioPeces
  from './componentes/vistas/productos-de-peces/accesorios/vista-detalle/vista';

import VistaDetalleAlimentoReptiles
  from './componentes/vistas/productos-de-reptiles/alimentos/vista-detalle/vista';
import AlimentosReptiles
  from './componentes/vistas/productos-de-reptiles/alimentos/alimentos';
import JaulaAves from './componentes/vistas/productos-de-aves/jaulas/jaulas';
import VistaDetalleJaulaAves
  from './componentes/vistas/productos-de-aves/jaulas/vista-detalle/vista';
  import AlimentosPeces
  from './componentes/vistas/productos-de-peces/alimentos/alimentos';
import VistaDetalleAlimentoPez
  from './componentes/vistas/productos-de-peces/alimentos/vista-detalle/vista';

import Mantenimiento from "./componentes/vistas/productos-de-peces/mantenimiento/mantenimiento"

import VistaDetalleMantPeces from './componentes/vistas/productos-de-peces/mantenimiento/vista-detalle/vista';
import VistaDetalleDelSnackDePerro from './componentes/vistas/productos-de-perros/snacks/vista-detalle/vista';
import AccesoriosRoedores from './componentes/vistas/productos-de-roedores/accesorios/accesorios';
import VistaDetalleAccesorioRoedor from './componentes/vistas/productos-de-roedores/accesorios/vista-detalle/vista';
import VistaServicios from './componentes/vista-servicios/vista-servicios';
import VistaBusqueda from './componentes/vistas/vistaBusqueda';
import RacionesGatoSecos from './componentes/vistas/productos-de-gatos/raciones/raciones';
import VistaDetalleDelProductoDeGato from './componentes/vistas/productos-de-gatos/raciones/vista-detalle/vista';
import RacionesGatoHumedas from './componentes/vistas/productos-de-gatos/racionesHumedas/raciones';
import VistaDetalleDelProductoDeGatoHumedos from './componentes/vistas/productos-de-gatos/racionesHumedas/vista-detalle/vista';
import VistaDetalleDelProductoDeBombasPeces from './componentes/vistas/productos-de-peces/bombas-y-filtros/vista-detalle/vista';
import BombasYfiltrosPeces from './componentes/vistas/productos-de-peces/bombas-y-filtros/productos';
import VistaDetalleDelProductoDePerroHumedos from './componentes/vistas/productos-de-perros/racionesHumedas/vista-detalle/vista';
import RacionesPerroHumedas from './componentes/vistas/productos-de-perros/racionesHumedas/raciones';
import ArenasSanitarias from './componentes/vistas/productos-de-gatos/arenas-sanitarias/arenas';
import VistaDetalleDeArenaSanitariaDeGato from './componentes/vistas/productos-de-gatos/arenas-sanitarias/vista-detalle/vista';
import Camas from './componentes/vistas/productos-de-perros/camas/camas';
import VistaDetalleCamas from './componentes/vistas/productos-de-perros/camas/vista-detalle/vista';
import CamasGatos from './componentes/vistas/productos-de-gatos/camas/camas';
import VistaDetalleCamasGatos from './componentes/vistas/productos-de-gatos/camas/vista-detalle/vista';
import Casillas from './componentes/vistas/productos-de-perros/casillas/casillas';
import VistaDetalleCasillas from './componentes/vistas/productos-de-perros/casillas/vista-detalle/vista';
import Rascadores from './componentes/vistas/productos-de-gatos/rascadores/rascadores';
import Platos from './componentes/vistas/productos-de-perros/platos/platos';
import VistaDetallePlatos from './componentes/vistas/productos-de-perros/platos/vista-detalle/vista';
import VistaDetalleHigieneGato from './componentes/vistas/productos-de-gatos/Higiene/vista-detalle/vista';
import HigieneGatos from './componentes/vistas/productos-de-gatos/Higiene/higiene';
import HigienePerros from './componentes/vistas/productos-de-perros/Higiene/higiene';
import VistaDetalleHigienePerro from './componentes/vistas/productos-de-perros/Higiene/vista-detalle/vista';
import FarmaciaAntiparasitarios from './componentes/vistas-de-otros-productos/farmacia/antiparasitarios-externos/antiparasitariosexternos';
import AntiparasitariosE from './componentes/vistas-de-otros-productos/farmacia/antiparasitarios-externos/antiparasitario-externo/antiparasitarioexterno';
import VistaDetalleAntiparsitariosExternos from './componentes/vistas-de-otros-productos/farmacia/antiparasitarios-externos/vista-detalle/vista';
import FarmaciaAntiparasitariosInternos from './componentes/vistas-de-otros-productos/farmacia/antiparasitarios-internos/antiparasitariosexternos';
import VistaDetalleAntiparsitariosInternos from './componentes/vistas-de-otros-productos/farmacia/antiparasitarios-internos/vista-detalle/vista';
import Transportadoras from './componentes/vistas/productos-de-perros/transportadoras/transportadora';
import VistaDetalleTrasportadorasPerros from './componentes/vistas/productos-de-perros/transportadoras/vista-detalle/vista';
import TransportadorasGatos from './componentes/vistas/productos-de-gatos/transportadoras/transportadora';
import VistaDetalleTrasportadorasGatos from './componentes/vistas/productos-de-gatos/transportadoras/vista-detalle/vista';
import PlatosGatos from './componentes/vistas/productos-de-gatos/platos/platos';
import VistaDetallePlatosGatos from './componentes/vistas/productos-de-gatos/platos/vista-detalle/vista';
import Juguetes from './componentes/vistas/productos-de-gatos/juguetes/juguetes/juguetes';
import PaseosPerros from './componentes/vistas/productos-de-perros/paseos/paseos';
import VistaDetalleDelProductoDePerroPaseos from './componentes/vistas/productos-de-perros/paseos/vista-detalle/vista';
import PaseosGatos from "./componentes/vistas/productos-de-gatos/paseos/paseos"
import VistaDetalleDelProductoDeGatoPaseos from "./componentes/vistas/productos-de-gatos/paseos/vista-detalle/vista"
import CepillosPerros from './componentes/vistas/productos-de-perros/cepillos/cepillos';
import VistaDetalleCepillosPerro from './componentes/vistas/productos-de-perros/cepillos/vista-detalle/vista';
import CepillosGatos from './componentes/vistas/productos-de-gatos/cepillos/cepillos';
import VistaDetalleCepillosGato from './componentes/vistas/productos-de-gatos/cepillos/vista-detalle/vista';
import Sanitarios from "./componentes/vistas/productos-de-gatos/sanitarios/sanitarios"
import VistaDetalleSanitario from "./componentes/vistas/productos-de-gatos/sanitarios/vista-detalle/vista"
import AccesoriosReptiles from './componentes/vistas/productos-de-reptiles/accesorios/accesorios';
import VistaPromociones from './componentes/vistas/vistaPromociones';
import PromocionesVista from './componentes/vistas/promociones';
import VistaDetalleJuguetes from './componentes/vistas/productos-de-gatos/juguetes/vista-detalle/vista';
import Juguete from './componentes/vistas/productos-de-gatos/juguetes/juguetes/juguete/juguete';
import JuguetePerro from './componentes/vistas/productos-de-perros/juguetes/juguetes/juguete/juguete';
import JuguetesPerro from './componentes/vistas/productos-de-perros/juguetes/juguetes/juguetes';
import NoHay from './componentes/404/404';
import VistaDetalleJuguetesP from './componentes/vistas/productos-de-perros/juguetes/vista-detalle/vista';
import Medicamentos from './componentes/vistas-de-otros-productos/farmacia/medicamentos/medicamentos';
import VistaDetalleMedicamentos from './componentes/vistas-de-otros-productos/farmacia/medicamentos/vista-detalle/vista';
import Suplementos from './componentes/vistas-de-otros-productos/farmacia/suplementos/suplementos';
import VistaDetalleSuplementos from './componentes/vistas-de-otros-productos/farmacia/suplementos/vista-detalle/vista';
import PerroRopa from './componentes/vistas/productos-de-perros/ropa/ropa';
import VistaRopaPerro from './componentes/vistas/productos-de-perros/ropa/vista/vista';
/* import Reducer from './features/Reducer'; */
import VistaDetalleDelProductoDeGatoSnack from './componentes/vistas/vista';
import SnacksGatos from './componentes/vistas/snacks';
function App () {
  return (
    <Provider store={store}>
      <ProSidebarProvider>

        <BrowserRouter>
        <Routes>
            <Route  path="/servicios" element={<VistaServicios />} />

        </Routes>
        <Routes>
            <Route  path="/" element={<Container />} />
          </Routes>
        <Routes>
            <Route  path="/busqueda" element={<VistaBusqueda />} />
          </Routes>
        <Routes>
            <Route  path="/vista-promociones/:id" element={<VistaPromociones />} />
          </Routes>
        <Routes>
            <Route  path="/vista-promociones" element={<PromocionesVista />} />
          </Routes>
          <Routes>
            <Route
         
              path="/productos-de-Reptiles/alimentos"
              element={<AlimentosReptiles />}
            />
            <Route
              path="/productos-de-Reptiles/alimentos/:id"
              element={<VistaDetalleAlimentoReptiles />}
            />
          </Routes>
          <Routes>
            <Route
         
              path="/productos-de-Reptiles/accesorios"
              element={<AccesoriosReptiles />}
            />
       
          </Routes>
          <Routes>
          <Route  path="/productos-de-Gatos/alimentosSecos" element={<RacionesGatoSecos />} />
          <Route  path="/productos-de-Gatos/alimentosSecos/:id" element={<VistaDetalleDelProductoDeGato />} />
          </Routes>
          <Routes>
          <Route  path="/productos-de-Gatos/juguetes" element={<Juguetes />} />
          <Route  path="/productos-de-Gatos/juguetes/:id" element={<VistaDetalleJuguetes />} />
          </Routes>
          <Routes>
          <Route  path="/productos-de-Gatos/higiene" element={<HigieneGatos />} />
          <Route  path="/productos-de-Gatos/higiene/:id" element={<VistaDetalleHigieneGato />} />
          </Routes>
          <Routes>
          <Route  path="/productos-de-Gatos/alimentosHumedos" element={<RacionesGatoHumedas />} />
          <Route  path="/productos-de-Gatos/alimentosHumedos/:id" element={<VistaDetalleDelProductoDeGatoHumedos />} />
          </Routes>
          <Routes>
          <Route  path="/productos-de-Gatos/arenasSanitarias" element={<ArenasSanitarias />} />
          <Route  path="/productos-de-Gatos/arenasSanitarias/:id" element={<VistaDetalleDeArenaSanitariaDeGato />} />
          </Routes>
          <Routes>
          <Route  path="/productos-de-Gatos/paseos" element={<PaseosGatos />} />
          <Route  path="/productos-de-Gatos/paseos/:id" element={<VistaDetalleDelProductoDeGatoPaseos />} />
          </Routes>
          <Routes>
          <Route  path="/productos-de-Gatos/cepillos" element={<CepillosGatos />} />
          <Route  path="/productos-de-Gatos/cepillos/:id" element={<VistaDetalleCepillosGato />} />
          </Routes>
        <Routes>
          
          <Route  path="/productos-de-Gatos/snacks" element={<SnacksGatos />} />
          <Route  path="/productos-de-Gatos/snacks/:id" element={<VistaDetalleDelProductoDeGatoSnack />} />
          </Routes>
          <Routes>
          <Route  path="/productos-de-Gatos/camas" element={<CamasGatos />} />
          <Route  path="/productos-de-Gatos/camas/:id" element={<VistaDetalleCamasGatos />} />
          </Routes>
          <Routes>
          <Route  path="/productos-de-Gatos/rascadores" element={<Rascadores />} />
          <Route  path="/productos-de-Gatos/rascadores/:id" element={<VistaDetalleRascadores />} />
          </Routes>
          <Routes>
          
          <Route  path="/productos-de-Gatos/transportadoras" element={<TransportadorasGatos />} />
          <Route  path="/productos-de-Gatos/transportadoras/:id" element={<VistaDetalleTrasportadorasGatos />} />
          </Routes>
          <Routes>
          
          <Route  path="/productos-de-Gatos/platos" element={<PlatosGatos />} />
          <Route  path="/productos-de-Gatos/platos/:id" element={<VistaDetallePlatosGatos />} />
          </Routes>

       
 
          <Routes>
          
          <Route  path="/productos-de-Gatos/sanitarios" element={<Sanitarios />} />
          <Route  path="/productos-de-Gatos/sanitarios/:id" element={<VistaDetalleSanitario />} />

          </Routes>
          <Routes>
          
          <Route  path="/productos-de-Gatos/accesorios" element={<NoHay />} />

          </Routes>
          <Routes>

           


            <Route path="/carrito" element={<Carrito />} />

          </Routes>
          <Routes>

             <Route path="/productos-de-Farmacia/antiparasitariosExternos" element={<FarmaciaAntiparasitarios />} /> 
             <Route path="/productos-de-Farmacia/antiparasitariosExternos/:id" element={<VistaDetalleAntiparsitariosExternos />} /> 
           </Routes>
          <Routes>

             <Route path="/productos-de-Farmacia/antiparasitariosInternos" element={<FarmaciaAntiparasitariosInternos />} /> 
             <Route path="/productos-de-Farmacia/antiparasitariosInternos/:id" element={<VistaDetalleAntiparsitariosInternos />} /> 
        </Routes>
          <Routes>

             <Route path="/productos-de-Farmacia/medicamentos" element={<Medicamentos />} /> 
             <Route path="/productos-de-Farmacia/medicamentos/:id" element={<VistaDetalleMedicamentos />} /> 
        </Routes>
          <Routes>

             <Route path="/productos-de-Farmacia/suplementos" element={<Suplementos />} /> 
             <Route path="/productos-de-Farmacia/suplementos/:id" element={<VistaDetalleSuplementos />} /> 
        </Routes>
          <Routes>
            <Route path="/blog" element={<Articulos />} />
            <Route path="/blog/:id" element={<Articulo />} />
          </Routes>

          <Routes>
            <Route
              path="/productos-de-Peces/accesorios"
              element={<AccesoriosPeces />}
            />
            <Route
              path="/productos-de-Peces/accesorios/:id"
              element={<VistaDetalleAccesorioPeces />}
            />
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Peces/alimentos"
              element={<AlimentosPeces />}
            />
            <Route
              path="/productos-de-Peces/alimentos/:id"
              element={<VistaDetalleAlimentoPez />}
            />
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Peces/bombasyfiltros"
              element={<BombasYfiltrosPeces />}
            />
            <Route
              path="/productos-de-Peces/bombasyfiltros/:id"
              element={<VistaDetalleDelProductoDeBombasPeces />}
            />
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Peces/mantenimientodelagua"
              element={<Mantenimiento />}
            />
            <Route
              path="/productos-de-Peces/mantenimientodelagua/:id"
              element={<VistaDetalleMantPeces />}
            />
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Peces/peceras"
              element={<NoHay />}
            />
       
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Peces/calentadoresytermometros"
              element={<NoHay />}
            />
       
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/alimentosSecos"
              element={<ProductosDePerros />}
            />
            <Route
              path="/productos-de-Perros/alimentosSecos/:id"
              element={<VistaDetalleDelProductoDePerro />}
            />

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/ropa"
              element={<PerroRopa />}
            />
            <Route
              path="/productos-de-Perros/ropa/:id"
              element={<VistaRopaPerro />}
            />

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/higiene"
              element={<HigienePerros />}
            />
            <Route
              path="/productos-de-Perros/higiene/:id"
              element={<VistaDetalleHigienePerro />}
            />

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/accesorios"
              element={<NoHay />}
            />
        
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/ropaG"
              element={<NoHay />}
            />
        
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/alimentosHumedos"
              element={<RacionesPerroHumedas />}
            />
            <Route
              path="/productos-de-Perros/alimentosHumedos/:id"
              element={<VistaDetalleDelProductoDePerroHumedos />}
            />

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/paseos"
              element={<PaseosPerros />}
            />
            <Route
              path="/productos-de-Perros/paseos/:id"
              element={<VistaDetalleDelProductoDePerroPaseos />}
            />

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/juguetes"
              element={<JuguetesPerro />}
            />
            <Route
              path="/productos-de-Perros/juguetes/:id"
              element={<VistaDetalleJuguetesP />}
            />
     

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/transportadoras"
              element={<Transportadoras />}
            />
            <Route
              path="/productos-de-Perros/transportadoras/:id"
              element={<VistaDetalleTrasportadorasPerros />}
            />

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/cepillos"
              element={<CepillosPerros />}
            />
            <Route
              path="/productos-de-Perros/cepillos/:id"
              element={<VistaDetalleCepillosPerro />}
            />

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/camas"
              element={<Camas />}
            />
            <Route
              path="/productos-de-Perros/camas/:id"
              element={<VistaDetalleCamas />}
            />

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Perros/casillas"
              element={<Casillas />}
            />
            <Route
              path="/productos-de-Perros/casillas/:id"
              element={<VistaDetalleCasillas />}
            />

          </Routes>
          <Routes>

            <Route path="/productos-de-Perros/snacks" element={<Snacks />} />
            <Route path="/productos-de-Perros/snacks/:id" element={<VistaDetalleDelSnackDePerro />} />
          </Routes>
          <Routes>

            <Route path="/productos-de-Perros/platos" element={<Platos />} />
            <Route path="/productos-de-Perros/platos/:id" element={<VistaDetallePlatos />} />
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Roedores/alimentos"
              element={<Alimentos />}
            />
            <Route
              path="/productos-de-Roedores/alimentos/:id"
              element={<VistaDetalleAlimentoRoedores />}
            />

          </Routes>
          <Routes>
            <Route path="/productos-de-Roedores/jaulas" element={<Jaulas />} />
            <Route
              path="/productos-de-Roedores/jaulas/:id"
              element={<VistaDetalleJaulasRoedores />}
            />

          </Routes>
          <Routes>
            <Route path="/productos-de-Roedores/accesorios" element={<AccesoriosRoedores />} />
            <Route
              path="/productos-de-Roedores/accesorios/:id"
              element={<VistaDetalleAccesorioRoedor />}
            />

          </Routes>
          <Routes>
            <Route
              path="/productos-de-Aves/alimentos"
              element={<AlimentosAves />}
            />
            <Route
              path="/productos-de-Aves/alimentos/:id"
              element={<VistaDetalleAlimentoAves />}
            />
          </Routes>
          <Routes>
            <Route path="/productos-de-Aves/jaulas" element={<JaulaAves />} />
            <Route
              path="/productos-de-Aves/jaulas/:id"
              element={<VistaDetalleJaulaAves />}
            />
          </Routes>
          <Routes>
            <Route
              path="/productos-de-Aves/accesorios"
              element={<AccesoriosAves />}
            />
            <Route
              path="/productos-de-Aves/accesorios/:id"
              element={<VistaDetalleAccesorioAves />}
            />
          </Routes>
        
        </BrowserRouter>
      </ProSidebarProvider>
    </Provider>
  );
}

export default App;
