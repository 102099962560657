import {Link} from 'react-router-dom';


const SnackGatos = ({prop}) => {
  return (
    <div className="col-4 col-lg-2 container-producto">
      <div className="container-imagen-producto">
   
       {/*  <div className=""> */}

          <img
            className="img-producto-perro"
            src={`https://d20fcseidpdftl.cloudfront.net/snacksGatos/${prop.url}`}
            alt={prop.id}
          />
       {/*  </div> */}
      </div>
      <span>

        <Link className="link-nombre-producto" to={`/productos-de-Gatos/snacks/${prop.id}`} state={prop}>
          {prop.nombre}
        </Link>
      </span>
  
    </div>
  );
};

export default SnackGatos;
